<!--拼图集字兑换列表-->
<template>
    <div class="jigsaw-content">
        <div class="head-content" ref="element">
<!--            <div class="or-nav">-->
                <van-tabs swipe-threshold="4" v-model="tabId" @change="changeState">
                    <van-tab v-for="(item,index) in tabList" :key="index">
                        <div slot="title">{{ item.text }}</div>
                    </van-tab>
                </van-tabs>
<!--            </div>-->
        </div>
        <van-list v-model="loading" :finished="finished" @load="pullUpLoadMore" ref="orderList">
            <van-cell v-for="item in orderList" :key="item.orderId" @click="viewDetail(item)">
                <div class="order-num">
                    <div style="display: flex;align-items: center">
                        <span class="or-no">订单号:{{item.cashNum}}</span>
                        <van-icon name="arrow"/>
                    </div>
                    <span class="font-gray" v-if="item.stateTxt">{{item.stateTxt}}</span>

                </div>
                <div class="product-img" v-if="item.cashdateilList.length > 1">
                    <van-image fit="contain" v-for="(product,index) in item.cashdateilList" :src="product.imgUrl"  :key="index"/>
                </div>
                <div class="product" v-else-if="item.cashdateilList.length > 0 ">
<!--                    <img v-lazy="item.cashdateilList[0].imgUrl">-->
                    <van-image fit="contain" :src="item.cashdateilList[0].imgUrl"/>
                    <div class="prod-desc">
                        <div class="ellipsis1">{{item.cashdateilList[0].prizename}}</div>
                        <div class="prod-num">x{{item.cashdateilList[0].cou}}</div>
                    </div>
                </div>
                <div class="order-amount">
                    共{{item.count}}件商品
                    合计：
                    <template v-for="(it,idx) in item.wordsList">
                        <span v-if=" it.wordsType  === 2" :key="idx">{{ it.wordsName }}</span>
                        <img :src="it.wordsName" v-else :key="idx"/><i :key="idx">x{{it.num}}</i>
                    </template>
                </div>
                <div class="order-btn" @click.stop="">
                    <div style="display: flex;align-items: center;justify-content: flex-end;">
                        <button type="button" @click="handleCancel(item)" v-if="tabId === 0">
                            取消
                        </button>
                        <button type="button" class="col" @click="handleBtnClick(item)" v-if="tabId === 0">
                            去付款
                        </button>
                        <button type="button" @click="confirmReceipt(item)" v-if="tabId === 2">
                            确认收货
                        </button>
                    </div>
                </div>
                <div class="reason" v-if="item.cancelReason">
                    <span class="font-pink">取消原因</span>：{{item.cancelReason}}
                </div>
            </van-cell>
        </van-list>
        <div class="no-data" v-if="finished && orderList.length < 1 ">
            <div class="bg-circle">
                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210414140256672.png">
            </div>
        </div>
        <van-popup v-model="showCancelLayer">
            <Cancel @cancel="confirmCancel"/>
        </van-popup>
        <screenPopup @close="showScreenPopup = false" :show="showScreenPopup" @search="search"></screenPopup>
    </div>
</template>

<script>
    import Cancel from "../../components/Order/Cancel";
    import SelectPayType from "../../components/Order/SelectPayType";
    import screenPopup from "../../components/screenPopup";


    export default {
        name: "ExchangeList",
        components: {Cancel, SelectPayType, screenPopup},
        data() {
            return {
                tabId: 0,
                tabList: [{text: '待付款', id: 1}, {text: '待发货', id: 2}, {text: '已发货', id: 3}, {text: '已完成', id: 5}, {text: '已取消', id: 4}],
                finished: false,
                loading: false,
                orderList: [],
                pageIndex: 0,
                pageSize: 10,
                showCancelLayer: false,
                currentOrder: 0, //当前选中的订单
                showPayLayer: false,
                orderCountByBid: 0,
                showScreenPopup: false,
            }
        },
        created() {
            if (this.$route.query.searchVal) {
                this.searchVal = this.$route.query.searchVal
            }
            if (this.$route.query.startTime) {
                this.startTime = this.$route.query.startTime
            }
            if (this.$route.query.endTime) {
                this.endTime = this.$route.query.endTime
            }
        },
        methods: {
            search(e) {
                this.pageIndex = 0
                this.orderList = []
                this.getOrderList()
                this.showScreenPopup = false
            },
            //上拉加载更多
            pullUpLoadMore() {
                this.pageIndex++;
                this.getOrderList();
            },
            //获取订单列表
            getOrderList() {
                this.loading = false
                if (this.loading) return
                this.loading = true
                let state = this.tabId
                if (this.tabId <= 2) state++
                if (this.tabId == 3) state = 5
                if (this.tabId == 4) state = 4
                this.post('/MVPuzzle/Cash/GetCashList', {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    state: state
                }, true, 2).then(res => {
                    this.loading = false;
                    if (res.code === 1) {
                        let {cashList, counts} = res.response;
                        if (cashList.length < this.pageSize) this.finished = true;
                        this.tabList.forEach((it) => {
                            it.text = counts["stateTxt" + it.id] || it.text
                        });
                        if (this.pageIndex === 1) this.orderList = [];
                        this.orderList = this.orderList.concat(cashList);
                    }
                });
            },
            //查看订单详情
            viewDetail(item) {
                this.$router.push("/Recharge/order_detail?orderId=" + item.cashNum + "&orderType=11");
            },
            handleBtnClick(item) {
                this.$router.push("/Recharge/order_detail?orderId=" + item.cashNum + "&orderType=11");
            },
            //点击状态、根据状态获取数据
            changeState(id) {
                this.initOrderParam();
            },
            initOrderParam() {
                this.loading = true;
                this.finished = false;
                this.pageIndex = 1;
                this.orderList = [];
                this.getOrderList(1);
            },
            //点击取消订单
            handleCancel(item) {
                this.$dialog.confirm({title: '提示', message: '是否确认取消该订单'}).then(() => {
                    this.currentOrder = item;
                    this.showCancelLayer = true;
                }).catch(() => {
                });
            },
            //确认取消订单
            confirmCancel(e) {
                let {type, message} = e;
                this.showCancelLayer = false;
                let {cashNum} = this.currentOrder
                if (type === 1) return
                this.$toast.loading({message: '订单取消中,,,', overlay: true, duration: 0})
                this.post("/MVPuzzle/Cash/UpdateCashOrders", {
                    cashId: cashNum,
                    type: 2,
                    reason: message
                }, true, 2).then(res => {
                    this.$toast.clear();
                    if (res.code === 1) {
                        this.$toast("取消成功!");
                        this.deleteFromList(cashNum);
                    } else {
                        this.$toast(res.msg);
                    }
                });
            },
            //从数组里删除数据
            deleteFromList(cashNum) {
                let idx = this.orderList.findIndex((it => {
                    return it.cashNum === cashNum
                }));
                if (idx >= 0) {
                    --this.tabList[this.tabId].Count;
                    this.orderList.splice(idx, 1);
                }
                if (this.orderList.length < 4) {
                }
            },
            //点击确认收货
            confirmReceipt(item) {
                this.$dialog.confirm({title: '提示', message: '是否确认已收到货'}).then(() => {
                    this.$toast.loading({message: '订单确认中,,,', overlay: true, duration: 0})
                    this.post("/MVPuzzle/Cash/UpdateCashOrders", {
                        cashId: item.cashNum,
                        type: 1,
                        reason: ''
                    }, true, 2).then(res => {
                        this.$toast.clear();
                        if (res.code === 1) {
                            this.$toast("确认成功!");
                            this.deleteFromList(item.cashNum);
                        } else {
                            this.$toast(res.msg);
                        }
                    }).catch(() => {
                    });
                });
            }
        },

    }
</script>

<style lang="scss">
    .jigsaw-content {
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        background-color: #F8F8F8;

        .no-data {
            padding-top: 120px;
            text-align: center;

            .bg-circle {
                position: relative;
                margin-top: 10%;
                margin-bottom: 6%;

                img {
                    max-width: 36%;
                }

                .icon-order {
                    margin-left: -16px;

                    &:before {
                        content: "\e966";
                    }
                }
            }

            .title {
                font-size: 18px;
                color: #333333;
                line-height: 30px;
                padding-top: 10px;
            }

            .tip {
                color: #999999;
                line-height: 30px;
            }
        }

        .head-content {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 100;

            .or-header {
                background: #fff;
                padding: 10px 15px 0;
                font-size: 0;

                .head-left {
                    width: calc(100% - 50px);
                    display: inline-block;
                    text-align: start;

                    .head-type {
                        p {
                            font-size: 14px;
                            color: #ff98a0;
                            display: inline-block;
                            background: #fff5f6;
                            border-radius: 30px;
                            padding: 3px 18px;
                            margin-bottom: 10px;
                            position: relative;
                            border: 1px solid #ff98a0;

                            &:nth-child(2) {
                                margin-left: 5px;
                            }

                            .icon {
                                width: 7px;
                                height: 7px;
                                display: inline-block;
                                border-top: 1px solid #ff98a0;
                                border-right: 1px solid #ff98a0;
                                transform: rotate(135deg);
                                -webkit-transform: rotate(135deg);
                                position: relative;
                                left: 7px;
                                top: -3px;
                            }

                            .brand-digit {
                            }
                        }
                    }
                }

                .head-right {
                    font-size: 14px;
                    color: #000000;
                    width: 50px;
                    display: inline-block;
                    text-align: right;
                    vertical-align: top;
                }
            }
            .van-tab--active {
                color: #ff98a0;
            }

            .van-tabs__line {
                background-color: #ff98a0;
            }
            .van-tab{
                overflow: visible;
            }
        }

        .van-list {
            padding: 0 0 10px;

            .prompt {
                color: #ff98a0;
                padding: 6px 15px;
                background-color: #fef2f3;
            }

            .van-cell {
                padding: 10px 0 0;
                margin-top: 10px;


            }

            .order-num {
                position: relative;
                display: flex;
                align-items: center;
                padding: 0 15px 10px;
                justify-content: space-between;
                border-bottom: 1px solid #f2f2f2;

                .or-bid {
                    color: #fff;
                    float: left;
                    padding: 0 2px;
                    border-radius: 3px;
                    margin-right: 5px;
                    background-color: #ff98a0;
                }

                .or-no {
                    position: relative;
                    max-width: 140px;
                    overflow: hidden;
                    white-space: nowrap;
                    display: inline-block;
                    text-overflow: ellipsis;
                }

                .van-icon-arrow {
                    display: inline-block;
                    vertical-align: middle;
                }

                .or-state {
                    position: relative;
                    color: #ff534a;
                    height: 22px;
                    line-height: 20px;
                    border-radius: 30px;
                    padding: 0 8px 0 18px;
                    display: inline-block;
                    border: 1px solid #ff4443;

                    .van-icon-success {
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        font-size: 18px;
                        transform: scale(0.5);
                        -webkit-transform: scale(0.5);
                    }

                    .icon-delete {
                        position: absolute;
                        top: 3px;
                        left: 4px;
                        font-size: 14px;

                        &:before {
                            content: "\e94c";
                            display: inline-block;
                        }
                    }

                    &.col-gray {
                        color: #333;
                        border: 1px solid #333;
                    }
                }

                p {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-right: 10px;
                }


            }

            .product {
                font-size: 0;
                position: relative;
                padding: 10px 15px 0;

                img {
                    width: 80px;
                    height: 80px;
                }

                .prod-desc {
                    width: calc(100% - 95px);
                    font-size: 12px;
                    display: inline-block;
                    vertical-align: top;
                    padding: 10px 0 0 15px;

                }

                .prod-num {
                    font-size: 12px;
                    color: #999;
                    position: absolute;
                    right: 15px;
                    top: 20px;
                }
            }

            .product-img {
                overflow-x: scroll;
                white-space: nowrap;
                padding: 10px 15px;

                .van-image {
                    width: 60px;
                    height: 60px;
                    margin-left: 10px;
                }
            }

            .order-desc {
                border-top: 1px solid #f1f1f1;
                padding: 10px 15px;

                p {
                    font-size: 12px;
                    color: #333;
                }
            }

            .order-amount {
                color: #999;
                display: flex;
                font-size: 12px;
                align-items: center;
                padding: 10px 15px;
                position: relative;
                flex-wrap: wrap;

                span {
                    font-size: 14px;
                }

                img {
                    width: 32px;
                    padding-right: 3px;


                }

                i {
                    font-size: 12px;
                    font-style: normal;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 15px;
                    right: 0;
                    height: 1px;
                    background-color: #f1f1f1;
                }
            }

            .order-btn {
                text-align: right;
                padding: 10px 15px;
                border-top: 1px solid #f1f1f1;

                button {
                    font-size: 14px;
                    color: #333333;
                    border: 1px solid #cccccc;
                    border-radius: 20px;
                    padding: 3px 16px;
                    background: #fff;
                    margin-left: 15px;
                    /*margin-right: 5px;*/
                    line-height: 20px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.col {
                        border-color: #ff98a0;
                        color: #ff98a0;
                    }
                }

                p {
                    float: left;
                }

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }

            .reason {
                padding: 11px 15px;
                text-align: right;
                border-top: 1px solid #f1f1f1;
            }

            .font-pink {
                color: #ff98a0;
            }

            .font-gray {
                color: #999;
            }

            .van-list__loading {
                .van-list__loading-text {
                    font-size: 16px;
                }
            }
        }

        .van-popup {
            border-radius: 6px;
        }

    }
</style>